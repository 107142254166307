<template>
  <div class="container-fluid pt-3">
    <!-- <Modal @close="toggleDeleteModal" :modalActive="delteActiveModal">
      <template v-slot:header>{{ t("gig.delete") }}</template>
      <template v-slot:body>
        <p>{{ t("gig.delete_confirm_inquire") }}</p>
      </template>
      <template v-slot:footer>
        <button @click="toggleDeleteModal" type="button" class="btn btn-secondary">{{ t("general.cancel") }}</button>
        <button @click="deleteGig" type="button" class="btn btn-danger">{{ t("general.delete") }}</button>
      </template>
    </Modal> -->
    <Modal @close="addModal = !addModal" :modalActive="addModal">
      <template v-slot:header>{{ t("gig.add") }}</template>
      <template v-slot:body>
        <div class="mb-3">
          <label for="gig_date_new" class="form-label">{{ t("general.date") }}</label>
          <input v-model="newGig.gig_date" type="date" class="form-control" id="gig_date_new" />
        </div>
        <div class="mb-3">
          <label for="gig_city_new" class="form-label">{{ t("general.city") }}</label>
          <input v-model="newGig.city" type="text" class="form-control" id="gig_city_new" />
        </div>
        <div class="mb-3">
          <label for="gig_country_new" class="form-label">{{ t("general.country") }}</label>
          <select
            class="form-select"
            id="gig_country_new"
            :aria-label="t('general.country')"
            v-model="newGig.country_id"
          >
            <option v-for="country in countries" :key="'gig_counry_new_' + country.id" :value="country.id">
              {{ country.name }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="gig_status_new" class="form-label">{{ t("general.status") }}</label>
          <select class="form-select" id="gig_status_new" :aria-label="t('general.status')" v-model="newGig.status">
            <option v-for="status in statuses" :key="'gig_status_new_' + status.id" :value="status.id">
              {{ status.name }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="gig_comment_new" class="form-label">{{ t("general.comment") }}</label>
          <textarea v-model="newGig.comment" class="form-control" id="gig_comment_new" />
        </div>
      </template>
      <template v-slot:footer>
        <button @click="addModal = !addModal" type="button" class="btn btn-secondary">{{ t("general.cancel") }}</button>
        <button @click="addGig" type="button" class="btn btn-success">{{ t("general.save") }}</button>
      </template>
    </Modal>
    <AddressesLinkSearch
      @close="searchActiveVenue = !searchActiveVenue"
      :searchActive="searchActiveVenue"
      :selectedCategoryId="selectedCategories"
    >
      <template #linkaction="linkaction">
        <div class="card-header d-flex justify-content-center">
          <button
            @click="updateVenue(linkaction.address.id)"
            type="button"
            class="btn btn-success btn-icon me-2"
            :title="t('general.link')"
          >
            <svg width="20" height="20" class="bi" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
            </svg>
            {{ t("general.link") }}
          </button>
        </div>
      </template>
    </AddressesLinkSearch>
    <AddressesLinkSearch
      @close="searchActiveArtist = !searchActiveArtist"
      :searchActive="searchActiveArtist"
      :selectedCategoryId="selectedCategories"
    >
      <template #linkaction="linkaction">
        <div class="card-header d-flex justify-content-center">
          <button
            @click="updateArtist(linkaction.address.id)"
            type="button"
            class="btn btn-success btn-icon me-2"
            :title="t('general.link')"
          >
            <svg width="20" height="20" class="bi" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
            </svg>
            {{ t("general.link") }}
          </button>
        </div>
      </template>
    </AddressesLinkSearch>
    <AddressesLinkSearch
      @close="searchActiveFestival = !searchActiveFestival"
      :searchActive="searchActiveFestival"
      :selectedCategoryId="selectedCategories"
    >
      <template #linkaction="linkaction">
        <div class="card-header d-flex justify-content-center">
          <button
            @click="updateFestival(linkaction.address.id)"
            type="button"
            class="btn btn-success btn-icon me-2"
            :title="t('general.link')"
          >
            <svg width="20" height="20" class="bi" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
            </svg>
            {{ t("general.link") }}
          </button>
        </div>
      </template>
    </AddressesLinkSearch>
    <AddressesLinkSearch
      @close="searchActiveOrganizer = !searchActiveOrganizer"
      :searchActive="searchActiveOrganizer"
      :selectedCategoryId="selectedCategories"
    >
      <template #linkaction="linkaction">
        <div class="card-header d-flex justify-content-center">
          <button
            @click="updateOrganizer(linkaction.address.id)"
            type="button"
            class="btn btn-success btn-icon me-2"
            :title="t('general.link')"
          >
            <svg width="20" height="20" class="bi" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
            </svg>
            {{ t("general.link") }}
          </button>
        </div>
      </template>
    </AddressesLinkSearch>
    <div class="gws_content_container p-3">
      <!-- title -->
      <div class="row g-3">
        <div class="col-md-12">
          <h1 class="text-center mb-0">{{ t("general.gig") }}</h1>
          <!-- <pre>{{ statuses }}</pre> -->
          <!-- <pre>{{ gig }}</pre> -->
        </div>
      </div>
      <div class="gws_address_search">
        <!-- resluts -->
        <div class="row">
          <div class="col">
            <div class="card my-2" :id="'gigcard_' + gig.id">
              <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs">
                  <li v-for="(navitem, index) in subnav" :key="'subnav_' + index" class="nav-item">
                    <button
                      @click="gig.subnavActive = navitem.id"
                      :class="'nav-link d-flex flex-row' + (navitem.id === gig.subnavActive ? ' active' : '')"
                      :aria-current="navitem.id === gig.subnavActive ? 'true' : 'false'"
                    >
                      <span>{{ navitem.title }}</span>
                      <svg
                        v-if="gig[navitem.id]"
                        width="20"
                        height="20"
                        fill="#currentColor"
                        style="margin-top: 0.15rem"
                      >
                        <use xlink:href="/assets/img/bootstrap-icons.svg#check"></use>
                      </svg>
                    </button>
                  </li>
                </ul>
              </div>
              <!-- main -->
              <div class="card-body">
                <div v-if="gig.subnavActive === 'base'" class="card-text">
                  <div class="row g-3">
                    <div class="col-xl-2 col-sm-4">
                      <label :for="'gig_date_' + gig.id" class="form-label">{{ t("general.date") }}</label>
                      <input v-model="gig.gig_date" type="date" class="form-control" :id="'gig_date_' + gig.id" />
                    </div>
                    <div class="col-xl-2 col-sm-4">
                      <label :for="'gig_city_' + gig.id" class="form-label">{{ t("general.city") }}</label>
                      <input v-model="gig.city" type="text" class="form-control" :id="'gig_city_' + gig.id" />
                    </div>
                    <div class="col-xl-2 col-sm-4">
                      <label :for="'gig_country_' + gig.id" class="form-label">{{ t("general.country") }}</label>
                      <select
                        class="form-select"
                        :id="'gig_country_' + gig.id"
                        :aria-label="t('general.country')"
                        v-model="gig.country_id"
                      >
                        <option v-for="country in countries" :key="gig.id + '_' + country.id" :value="country.id">
                          {{ country.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-xl-2 col-sm-4">
                      <label :for="'gig_status_' + gig.id" class="form-label"
                        >{{ t("general.status") }}
                        <span class="status-marker" :style="currentStatusColors(gig.status)"></span>
                      </label>
                      <select
                        class="form-select"
                        :id="'gig_status_' + gig.id"
                        :aria-label="t('general.status')"
                        v-model="gig.status"
                      >
                        <option
                          v-for="status in statuses"
                          :key="gig.id + '_' + status.id"
                          :value="status.id"
                          :style="getStatusStyles(status)"
                        >
                          {{ status.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-xl-4 col-sm-8">
                      <label :for="'gig_comment_' + gig.id" class="form-label">{{ t("general.comment") }}</label>
                      <textarea v-model="gig.comment" class="form-control" :id="'gig_comment_' + gig.id" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 border-top mt-3 pt-2">
                      <button
                        @click="save(gig)"
                        type="button"
                        class="btn btn-success btn-icon me-2"
                        :title="t('general.save')"
                      >
                        <svg class="bi" width="20" height="20" fill="currentColor">
                          <use xlink:href="/assets/img/bootstrap-icons.svg#check"></use>
                        </svg>
                        {{ t("general.save") }}
                      </button>
                      <!-- <button
                        @click="toggleDeleteModal(gig.id)"
                        type="button"
                        class="btn btn-danger btn-icon me-2"
                        :title="t('general.delete')"
                      >
                        <svg class="bi" width="20" height="20" fill="currentColor">
                          <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
                        </svg>
                        {{ t("general.delete") }}
                      </button> -->
                    </div>
                  </div>
                </div>
                <!-- artist -->
                <div v-if="gig.subnavActive === 'artist'" class="card-text">
                  <div v-if="gig.artist !== null">
                    <CardText :address="gig.artist" :countries="countries">
                      <template #cardaction>
                        <router-link
                          :to="'/addresses/edit/' + gig.artist.id"
                          class="btn btn-success btn-icon me-2 is-rows-el edit-btn"
                          :title="t('general.edit')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                          </svg>
                          {{ t("general.edit") }}
                        </router-link>
                        <button
                          @click="showModal('Künstler', gig.id)"
                          type="button"
                          class="btn btn-warning btn-icon me-2"
                          :title="t('general.change_assignment')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                          </svg>
                          {{ t("general.change_assignment") }}
                        </button>
                        <button
                          @click="
                            currentGigId = gig.id;
                            updateVenue(null);
                          "
                          type="button"
                          class="btn btn-danger btn-icon me-2"
                          :title="t('general.remove')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                          </svg>
                          {{ t("general.remove") }}
                        </button>
                      </template>
                    </CardText>
                  </div>
                  <div v-else>
                    <button
                      @click="showModal('Künstler', gig.id)"
                      type="button"
                      class="btn btn-success btn-icon me-2"
                      :title="t('general.add')"
                    >
                      <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                      </svg>
                      {{ t("general.add") }}
                    </button>
                  </div>
                  <div v-if="gig.stage !== null">
                    <StageCardText :stage="gig.stage"></StageCardText>
                  </div>
                </div>
                <!-- venue -->
                <div v-if="gig.subnavActive === 'venue'" class="card-text">
                  <div v-if="gig.venue !== null">
                    <CardText :address="gig.venue" :countries="countries">
                      <template #cardaction>
                        <router-link
                          :to="'/addresses/edit/' + gig.venue.id"
                          class="btn btn-success btn-icon me-2 is-rows-el edit-btn"
                          :title="t('general.edit')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                          </svg>
                          {{ t("general.edit") }}
                        </router-link>
                        <button
                          @click="showModal('Venue', gig.id)"
                          type="button"
                          class="btn btn-warning btn-icon me-2"
                          :title="t('general.change_assignment')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                          </svg>
                          {{ t("general.change_assignment") }}
                        </button>
                        <button
                          @click="
                            currentGigId = gig.id;
                            updateVenue(null);
                          "
                          type="button"
                          class="btn btn-danger btn-icon me-2"
                          :title="t('general.remove')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                          </svg>
                          {{ t("general.remove") }}
                        </button>
                      </template>
                    </CardText>
                  </div>
                  <div v-else>
                    <button
                      @click="showModal('Venue', gig.id)"
                      type="button"
                      class="btn btn-success btn-icon me-2"
                      :title="t('general.add')"
                    >
                      <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                      </svg>
                      {{ t("general.add") }}
                    </button>
                  </div>
                  <div v-if="gig.stage !== null">
                    <!-- TODO: stage -->
                    <StageCardText :stage="gig.stage"></StageCardText>
                  </div>
                </div>
                <!-- festival -->
                <template v-if="gig.subnavActive === 'festival'">
                  <template v-if="gig.festival !== null">
                    <CardText :address="gig.festival" :countries="countries">
                      <template #cardaction>
                        <router-link
                          :to="'/addresses/edit/' + gig.festival.id"
                          class="btn btn-success btn-icon me-2 is-rows-el edit-btn"
                          :title="t('general.edit')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                          </svg>
                          {{ t("general.edit") }}
                        </router-link>
                        <button
                          @click="showModal('Festival', gig.id)"
                          type="button"
                          class="btn btn-warning btn-icon me-2"
                          :title="t('general.change_assignment')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                          </svg>
                          {{ t("general.change_assignment") }}
                        </button>
                        <button
                          @click="
                            currentGigId = gig.id;
                            updateFestival(null);
                          "
                          type="button"
                          class="btn btn-danger btn-icon me-2"
                          :title="t('general.remove')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                          </svg>
                          {{ t("general.remove") }}
                        </button>
                      </template>
                    </CardText>
                  </template>
                  <template v-else>
                    <button
                      @click="showModal('Festival', gig.id)"
                      type="button"
                      class="btn btn-success btn-icon me-2"
                      :title="t('general.add')"
                    >
                      <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                      </svg>
                      {{ t("general.add") }}
                    </button>
                  </template>
                </template>
                <!-- organizer -->
                <div v-if="gig.subnavActive === 'organizer'" class="card-text">
                  <div v-if="gig.organizer !== null">
                    <CardText :address="gig.organizer" :countries="countries">
                      <template #cardaction>
                        <router-link
                          :to="'/addresses/edit/' + gig.organizer.id"
                          class="btn btn-success btn-icon me-2 is-rows-el edit-btn"
                          :title="t('general.edit')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                          </svg>
                          {{ t("general.edit") }}
                        </router-link>
                        <button
                          @click="showModal('Veranstalter', gig.id)"
                          type="button"
                          class="btn btn-warning btn-icon me-2"
                          :title="t('general.change_assignment')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                          </svg>
                          {{ t("general.change_assignment") }}
                        </button>
                        <button
                          @click="
                            currentGigId = gig.id;
                            updateOrganizer(null);
                          "
                          type="button"
                          class="btn btn-danger btn-icon me-2"
                          :title="t('general.remove')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                          </svg>
                          {{ t("general.remove") }}
                        </button>
                      </template>
                    </CardText>
                  </div>
                  <div v-else>
                    <button
                      @click="showModal('Veranstalter', gig.id)"
                      type="button"
                      class="btn btn-success btn-icon me-2"
                      :title="t('general.add')"
                    >
                      <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                      </svg>
                      {{ t("general.add") }}
                    </button>
                  </div>
                  <!-- <pre>{{ gig }}</pre> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { useApiQuery } from "@/composables/useApiQueries";
import { getContrastColor } from "@/composables/utilities.js";
import axios from "axios";
import storeConfig from "../store/config";
import { useToast } from "vue-toastification";
import Modal from "@/components/Modal.vue";
// import AddressNavigation from "@/components/navigation/Address.vue";
import CardText from "@/components/addresses/CardText.vue";
import StageCardText from "@/components/stages/CardText.vue";
import AddressesLinkSearch from "@/components/addresses/AddressesLinkSearch.vue";
export default {
  name: "gig",
  components: {
    Modal,
    // AddressNavigation,
    CardText,
    StageCardText,
    AddressesLinkSearch,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const toast = useToast();
    const currentUser = computed(() => store.getters.user);
    const subnav = ref([
      { id: "base", title: t("general.gig") },
      { id: "artist", title: t("general.artist") },
      { id: "venue", title: t("general.venue") },
      { id: "festival", title: t("general.festival") },
      { id: "organizer", title: t("general.organizer") },
    ]);
    const activeTab = ref({
      id: null,
      tab: "base",
    });
    const currentGigId = ref(null);
    const { result: gig, query: getGig } = useApiQuery({
      url: "gig/" + route.params.id,
      defaultVal: {},
      params: {},
      pfn: function (data) {
        if (data.gig_date) {
          data.gig_date = data.gig_date.substr(0, 10);
        }
        if (activeTab.value.id === data.id) {
          data.subnavActive = activeTab.value.tab;
        } else {
          data.subnavActive = "base";
        }
        return data;
      },
    });
    const showGigsNumber = ref(25);
    function loadMoreData() {
      showGigsNumber.value += 25;
    }
    // const { result: address, query: getAddress } =
    // useApiQuery({ url: "addresses/" + route.params.id, defaultVal: [] });
    const { result: countries, query: getCountries } = useApiQuery({ url: "country", defaultVal: [] });
    const { result: statuses, query: getStatuses } = useApiQuery({
      url: "status",
      defaultVal: [],
      pfn: function (data) {
        return data.map((status) => {
          status.textcolor = getContrastColor(status.color);
          return status;
        });
      },
    });
    const {
      result: categories,
      query: getCategories,
      error: categoriesError,
    } = useApiQuery({ url: "category", defaultVal: [] });

    async function loadCategories() {
      await getCategories();
      if (categoriesError) {
        if (categoriesError.response && categoriesError.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      }
    }

    const selectedCategories = ref("");
    function setSelectedCategories(type) {
      selectedCategories.value = categories.value
        .filter((category) => category[type] == 1)
        .map((category) => category.id)
        .join(",");
      // console.log(selectedCategories.value);
    }

    async function getAll() {
      await Promise.all([getGig(), getCountries(), getStatuses(), loadCategories()]);
    }

    async function save(gig) {
      activeTab.value.id = gig.id;
      activeTab.value.tab = "base";
      const data = {
        gig_date: gig.gig_date,
        city: gig.city,
        country_id: gig.country_id,
        status: gig.status,
        comment: gig.comment,
        updated_by: currentUser.value.id,
      };
      try {
        await store.dispatch("addLoading", 1);
        await axios.put(storeConfig.apiBaseUrl + "gig/" + gig.id, data);
        toast.success(t("general.gig") + " " + t("general.successfully") + " " + t("general.edited") + ".");
        getAll();
      } catch (error) {
        if (error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    // const selectedGig = ref(null);
    // const delteActiveModal = ref(false);
    // const toggleDeleteModal = (id) => {
    //   if (id) {
    //     selectedGig.value = id;
    //   }
    //   delteActiveModal.value = !delteActiveModal.value;
    // };

    // async function deleteGig() {
    //   // console.log(selectedGig.value);
    //   if (selectedGig.value) {
    //     try {
    //       await store.dispatch("addLoading", 1);
    //       await axios.delete(storeConfig.apiBaseUrl + "gig/" + selectedGig.value);
    //       toggleDeleteModal();
    //       toast.success(t("general.gig") + " " + t("general.successfully") + " " + t("general.deleted") + ".");
    //       await getGig();
    //     } catch (error) {
    //       if (error.response && error.response.status == 401) {
    //         store.dispatch("logout");
    //         router.push({ name: "Login" });
    //       }
    //     } finally {
    //       await store.dispatch("addLoading", -1);
    //     }
    //   }
    // }

    const addModal = ref(false);
    const newGig = ref({
      gig_date: null,
      city: null,
      country_id: null,
      status: null,
      comment: null,
    });
    // artist_id: route.params.id,

    async function addGig() {
      addModal.value = false;
      const data = {
        gig_date: newGig.value.gig_date,
        city: newGig.value.city,
        country_id: newGig.value.country_id,
        status: newGig.value.status,
        comment: newGig.value.comment,
        created_by: currentUser.value.id,
        updated_by: currentUser.value.id,
        // artist_id: address.value.id,
      };
      try {
        await store.dispatch("addLoading", 1);
        let result = await axios.post(storeConfig.apiBaseUrl + "gig", data);
        if (result.data.id) {
          toast.success(t("general.gig") + " " + t("general.successfully") + " " + t("general.added") + ".");
          await getGig();
          newGig.value.gig_date = null;
          newGig.value.city = null;
          newGig.value.country_id = null;
          newGig.value.status = null;
          newGig.value.comment = null;
        }
      } catch (error) {
        if (error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
      // console.log(data);
    }

    async function updateDependencies(data, area) {
      try {
        await store.dispatch("addLoading", 1);
        await axios.put(storeConfig.apiBaseUrl + "gig/" + currentGigId.value, data);
        toast.success(t("general." + area) + " " + t("general.successfully") + " " + t("general.edited") + ".");
        await getGig();
      } catch (error) {
        if (error.response && error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    async function updateVenue(id) {
      searchActiveVenue.value = false;
      activeTab.value.id = currentGigId.value;
      activeTab.value.tab = "venue";
      const data = {
        venue_id: id,
        updated_by: currentUser.value.id,
      };
      // console.log(data, currentGigId.value);
      updateDependencies(data, activeTab.value.tab);
    }

    async function updateArtist(id) {
      searchActiveArtist.value = false;
      activeTab.value.id = currentGigId.value;
      activeTab.value.tab = "artist";
      const data = {
        artist_id: id,
        updated_by: currentUser.value.id,
      };
      // console.log(data, currentGigId.value);
      updateDependencies(data, activeTab.value.tab);
    }

    async function updateFestival(id) {
      searchActiveFestival.value = false;
      activeTab.value.id = currentGigId.value;
      activeTab.value.tab = "festival";
      const data = {
        festival_id: id,
        updated_by: currentUser.value.id,
      };
      updateDependencies(data, activeTab.value.tab);
    }

    async function updateOrganizer(id) {
      searchActiveOrganizer.value = false;
      activeTab.value.id = currentGigId.value;
      activeTab.value.tab = "organizer";
      const data = {
        organizer_id: id,
        updated_by: currentUser.value.id,
      };
      updateDependencies(data, activeTab.value.tab);
    }

    const searchActiveVenue = ref(false);
    const searchActiveArtist = ref(false);
    const searchActiveFestival = ref(false);
    const searchActiveOrganizer = ref(false);

    function showModal(type, id) {
      currentGigId.value = id;
      setSelectedCategories(type);
      if (type == "Künstler") {
        searchActiveArtist.value = !searchActiveArtist.value;
      }
      if (type == "Venue") {
        searchActiveVenue.value = !searchActiveVenue.value;
      }
      if (type == "Veranstalter") {
        searchActiveOrganizer.value = !searchActiveOrganizer.value;
      }
      if (type == "Festival") {
        searchActiveFestival.value = !searchActiveFestival.value;
      }
    }

    function getStatusStyles(status) {
      return "background-color:#" + status.color + ";color:" + status.textcolor + ";";
    }

    function currentStatusColors(id) {
      const status = statuses.value.find((status) => status.id == id);
      return getStatusStyles(status);
    }

    getAll();
    return {
      t,
      subnav,
      gig,
      showGigsNumber,
      loadMoreData,
      // address,
      countries,
      statuses,
      // displayName: computed(() => {
      //   if (address.value.name) {
      //     return address.value.name;
      //   } else if (address.value.forename && address.value.surname) {
      //     return address.value.forename + " " + address.value.surname;
      //   } else if (address.value.forename) {
      //     return address.value.forename;
      //   } else if (address.value.surname) {
      //     return address.value.surname;
      //   } else {
      //     return "";
      //   }
      // }),
      save,
      // delteActiveModal,
      // toggleDeleteModal,
      // deleteGig,
      newGig,
      selectedCategories,
      setSelectedCategories,
      searchActiveVenue,
      searchActiveArtist,
      searchActiveFestival,
      searchActiveOrganizer,
      showModal,
      currentGigId,
      updateVenue,
      updateArtist,
      updateFestival,
      updateOrganizer,
      addModal,
      addGig,
      getStatusStyles,
      currentStatusColors,
    };
  },
};
</script>
